@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

body,
* {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* scroll-behavior: smooth; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rc-slider-track {
  background-color: #2e90fa !important;
}

.rc-slider-handle {
  border-color: #2e90fa !important;
  /* background-color: #2e90fa  !important; */
}

.text-animate {
  display: inline-block;
  opacity: 0;
  animation: bounceIn 1s 1s;
  animation-fill-mode: forwards;
  min-width: 10px;
}

/* Icon 4 */

#nav-icon4 {
  width: 37px;
  height: 30px;
  position: relative;
  /* margin: 50px auto; */
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon4 span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

/* #nav-icon4 {
  display: flex;
} */

#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 11px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 22px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -1px;
  left: 4px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 25px;
  left: 4px;
}

.navbar_menu {
  position: relative;
  z-index: 3;
  color: #fff;
  text-align: center;
  padding-top: 96px;
  transform: perspective(2000px) rotateX(0deg) rotateY(0deg);
}

.parallax {
  width: 100%;
  height: 100%;
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 0.8;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  max-width: 400px;
}

.parallax .scroller {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
  display: flex;
  white-space: nowrap;
  letter-spacing: 2px;
  display: flex;
  flex-wrap: nowrap;
}

.parallax span {
  display: block;
  margin-right: 30px;
}

#recaptcha > div {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
